
const initLatitude = 10.4640788;
const initLongitude = -68.0988114;

//Data:
function data() {
  return {
    initLatitud: initLatitude,
    initLongitud: initLongitude,
    mapOptions: {
      center: { lat: initLatitude, lng: initLongitude },
      zoom: 12,
      mapTypeControl: true
    },
    placeholderPos: {
      lat: 0,
      lng: 0
    },
    markerOptions: {
      position: { lat: initLatitude, lng: initLongitude },
      title: 'Puerto',
      draggable: true
    },
    autocomplete: null,
    places: null,
    map: null
  }
}

//Meodos:
function updatePosition(numLat, numLng) {
  this.mapOptions = {
    ...this.mapOptions,
    center: { lat: numLat, lng: numLng }
  };
  this.markerOptions.position = { lat: numLat, lng: numLng };
}
function handleMarkerPosition(pos) {
  if(this.Latitude != '' || this.Longitude != ''){
    this.Latitude = '';
    this.Longitude = '';
  }

  this.placeholderPos.lat = pos.lat.toFixed(7);
  this.placeholderPos.lng = pos.lng.toFixed(7);
  this.$v.Latitude.$touch();
  this.$v.Longitude.$touch();
}
function ready(map) {
  this.map = map
  this.$GMaps().then((maps) => {
    this.places = new maps.places.PlacesService(map)     
    this.autocomplete = new maps.places.Autocomplete(this.$refs.autocomplete)
    this.autocomplete.addListener('place_changed', this.update)
  });
}
function update() {
  const place = this.autocomplete.getPlace()
  if (place.geometry) {
    this.map.panTo(place.geometry.location)
    this.markerOptions.position = place.geometry.location
    this.handleMarkerPosition(place.geometry.location);
  }
}
function setMarker($event) {
  const position = $event.latLng.toJSON();
  this.handleMarkerPosition(position);
  this.markerOptions.position = position;
}

//Computeds


export default {
  data,
  methods: {
    ready,
    update,
    setMarker,
    updatePosition,
    handleMarkerPosition
  },
  computed: {}
}